<template>
    <nav v-if="authenticated">
        <v-app-bar rounded dark app color="#FFFFFF" class="d-print-none">
            <v-app-bar-nav-icon
                class="black--text"
                @click.stop="sidebar = !sidebar"
            ></v-app-bar-nav-icon>
            <v-toolbar-title class="text-subtitle-1 black--text">
                <strong>{{ menu }}</strong> {{ submenu }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title class="text-caption black--text">
                {{ new Date() | moment("dddd, DD MMMM YYYY H:mm") }}
            </v-toolbar-title>
        </v-app-bar>
        <v-navigation-drawer
            app
            v-model="sidebar"
            color="#61B15A"
            class="d-print-none"
        >
            <v-layout>
                <v-flex class="ml-4 my-8">
                    <v-list-item-title
                        class="text-h5 font-weight-bold white--text"
                    >
                        <v-img
                            class="rounded-lg"
                            max-width="170"
                            lazy-src="@/assets/img/logo.jpg"
                            src="@/assets/img/logo.jpeg"
                        />
                    </v-list-item-title>
                    <v-list-item-title class="text-subtitle-1 white--text">
                        Dashboard Version 1.0
                    </v-list-item-title>
                </v-flex>
            </v-layout>
            <v-divider></v-divider>
            {{ active(id) }}
            <v-list dark>
                <v-list-item to="/dashboard">
                    <v-list-item-icon>
                        <v-icon>mdi-view-dashboard</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="text-subtitle-1">
                        Dashboard
                    </v-list-item-title>
                </v-list-item>

                <v-list-group
                    v-for="item in items"
                    :key="item.title"
                    v-model="item.active"
                    :prepend-icon="item.icon"
                    active-class="white--text"
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title
                                class="text-subtitle-1"
                                v-text="item.title"
                            ></v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item
                        v-for="child in item.items"
                        :to="child.route"
                        :key="child.title"
                        active-class="white--text"
                        link
                    >
                        <v-list-item-content>
                            <v-list-item-title
                                class="ml-7 font-weight-normal text-subtitle-2"
                                v-text="child.title"
                            ></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list>
            <template v-slot:append>
                <v-sheet color="#579F51">
                    <v-list-item class="pa-0">
                        <v-list-item-avatar class="ml-3" size="42">
                            <v-img src="@/assets/img/avatar.png" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-card-title
                                class="pa-0 ma-0 text-subtitle-1 white--text"
                            >
                                {{ user.dataUser.name }}
                            </v-card-title>
                            <v-card-title
                                class="pa-0 ma-0 text-caption white--text"
                                style="line-height: normal"
                            >
                                {{ user.dataUser.roles_name }}
                            </v-card-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-card-actions class="mx-1 pt-0 pb-3">
                        <v-btn
                            block
                            small
                            color="#EB4646"
                            class="white--text"
                            style="text-transform: unset !important"
                            @click="logout"
                        >
                            Keluar
                        </v-btn>
                    </v-card-actions>
                </v-sheet>
            </template>
        </v-navigation-drawer>
    </nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    props: { id: String, menu: String, submenu: String },
    data: () => ({
        sidebar: true,
        items: [
            {
                title: "Laporan",
                icon: "mdi-chart-box",
                active: false,
                items: [
                    { title: "Transaksi Scanner", route: "/laporanScanner" },
                    { title: "Transaksi Inbound", route: "/laporanInbound" },
                    { title: "Transaksi Outbound", route: "/laporanOutbound" },
                    {
                        title: "Transaksi Stock Opname",
                        route: "/laporanStockOpname",
                    },
                    {
                        title: "Transaksi In Transit",
                        route: "/laporanInTransit",
                    },
                    { title: "Master Barcode", route: "/masterBarcode" },
                    { title: "Stock", route: "/laporanStock" },
                ],
            },
            {
                title: "Transaksi",
                icon: "mdi-note-text",
                active: false,
                items: [
                    {
                        title: "Terima Data",
                        route: "/terimaData",
                    },
                    {
                        title: "Surat Jalan Antar Gudang",
                        route: "/transaksiAntarGudang",
                    },
                    { title: "Surat Jalan Sales", route: "/transaksiSales" },
                    { title: "Surat Jalan Sample", route: "/transaksiSampel" },
                    { title: "Surat Jalan Donasi", route: "/transaksiDonasi" },
                    { title: "Surat Jalan Event", route: "/transaksiEvent" },
                    { title: "Stock Opname", route: "/transaksiStockOpname" },
                    {
                        title: "TP Gd. Retur ke Gd. Stock",
                        route: "/transaksiReturStock",
                    },
                    { title: "Print Barcode", route: "/print-barcode" },
                ],
            },
            {
                title: "Master Data",
                icon: "mdi-key",
                active: false,
                items: [
                    { title: "Gudang", route: "/gudang" },
                    { title: "Tipe Gudang", route: "/tipeGudang" },
                    { title: "Tipe Box", route: "/tipeBox" },
                    { title: "Tipe Penerimaan", route: "/tipePenerimaan" },
                    { title: "Tipe Pengeluaran", route: "/tipePengeluaran" },
                    { title: "User Web", route: "/userWeb" },
                    { title: "User Barcode", route: "/userBarcode" },
                    { title: "Customer", route: "/customer" },
                    { title: "Devices", route: "/devices" },
                    { title: "Material", route: "/material" },
                    { title: "Signature", route: "/signature" },
                    { title: "Role", route: "/role" },
                ],
            },
        ],
    }),
    computed: {
        ...mapGetters({
            authenticated: "auth/authenticated",
            user: "auth/user",
        }),
    },
    methods: {
        ...mapActions({
            logoutAction: "auth/logOut",
        }),

        active(value) {
            if (value !== "99") {
                this.items[value].active = true;
            } else {
                this.items.active = false;
            }
        },
        logout() {
            this.logoutAction().then(() => {
                this.$router.replace({
                    name: "Login",
                });
            });
        },
    },
};
</script>
